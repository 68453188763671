<template>
  <div>
    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>

    <s-table
      ref="table"
      size="middle"
      :loading="loading"
      :columns="columns"
      :data="loadData"
      rowKey="id"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="sort_order_tags" slot-scope="sort_order_tags">
        <a-tag
          :color="sort_order_tags === 1 ? 'volcano' : 'geekblue'"
        >
          {{ sort_order_tags === 1 ? '一级类目' : '二级类目' }}
        </a-tag>
      </span>
      <span slot="img" slot-scope="img, record">
        <a-avatar
          slot="img"
          :src="img"
          shape="square"
          @click="openPreview(img)"
        />
        <a :href="img" target="_blank" v-if="false">{{ record.name }}</a>
      </span>
      <span slot="img2" slot-scope="img2, record">
        <a-avatar
          v-if="(getFileSuffix(img2) !== 'pdf') && img2"
          slot="img2"
          :src="img2"
          shape="square"
          @click="openPreview(img2)"
        />
        <a :href="img2" v-if="getFileSuffix(img2) === 'pdf'" target="_blank">{{ record.name }}</a>
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelet(record)"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      v-if="visible"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <a-modal v-model="previewvisible" title="预览" width="350px" :footer="null">
      <img alt="图片错误" style="width: 300px; margin: 0 auto" :src="previewurl" />
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import {
  addChildCategory,
  addParentCategory,
  deleteChildCategory,
  deleteParentCategory,
  listParentCategory
} from '@/api/wx_shopmall_manage'
import CreateForm from '@/views/wx_shopmall_manage/CreateForm'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm
  },
  data () {
    return {
      loading: false,
      confirmLoading: true,
      dictionaries: Dictionaries,
      // 创建窗口控制
      visible: false,
      previewvisible: false,
      previewurl: '',
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '100px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '类目名',
          dataIndex: 'category_name'
        },
        {
          title: '类目图标',
          dataIndex: 'icon_url',
          scopedSlots: { customRender: 'img' }
        },
        {
          title: '类目图片',
          dataIndex: 'picture_url',
          scopedSlots: { customRender: 'img2' }
        },
        {
          title: '关键字',
          dataIndex: 'keywords',
          ellipsis: true
        },
        {
          title: '简介',
          dataIndex: 'desc',
          ellipsis: true
        },
        {
          title: '级别',
          dataIndex: 'level',
          ellipsis: true,
          scopedSlots: { customRender: 'sort_order_tags' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return listParentCategory(Object.assign(parameter, this.queryParam))
          .then(res => {
            res.data.entries.map((e, i, arr) => {
              e.level = 1
              e.children.map((ee, ii, arr2) => {
                ee.category_name = ee.name
                ee.key = ee.id
                // ee.icon_url = ee.iconUrl
                // ee.picture_url = ee.picUrl
                ee.level = 2
                ee.parent_name = e.category_name
                ee.sort_order = ee.sort_order === undefined ? 2 : ee.sort_order
              })
            })
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          }
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
    console.log(this.$Dictionaries)
  },
  methods: {
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            }
          },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
      this.confirmLoading = false
    },
    handleAddCancel () {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      // this.$emit('onEdit', record)
      this.$router.push({ path: '/shopmall/goods_category/' + record.id })
    },
    handleDelet (record) {
      if (record.sort_order === 1) {
        deleteParentCategory(record.id).then((res) => {
          console.log(res)
          console.log('删除成功------')
          this.$refs.table.refresh()
        })
      } else {
        deleteChildCategory(record.id).then((res) => {
          console.log(res)
          console.log('删除成功------')
          this.$refs.table.refresh()
        })
      }
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          if (values.level === 1) {
            addParentCategory(values).then((res) => {
              console.log(res, '创建一级成功')
              this.visible = false
              this.confirmLoading = false
              this.$refs.table.refresh()
            })
          } else {
            values.name = values.category_name
            // values.iconUrl = values.icon_url
            // values.picUrl = values.picture_url
            // values.category_name = undefined
            addChildCategory(values).then((res) => {
              console.log(res, '创建二级成功')
              this.visible = false
              this.confirmLoading = false
              this.$refs.table.refresh()
            })
          }
        } else {
          console.log('values---000', errors)
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    },
    getFileSuffix: function (filename) {
      return filename.substring(filename.lastIndexOf('.') + 1).toLowerCase()
    },
    openPreview (url) {
      this.previewvisible = true
      this.previewurl = url
    }
  }
}
</script>
